<template>
  <MainCard>
    <template v-slot:body>
      <v-tabs
        v-model="tab"
        show-arrows
        fixed-tabs
      >
        <v-tab
          v-for="(tabItem,index) in tabs"
          :key="tabItem.icon"
          @click="reRenderComponent(index)"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tabItem.icon }}
          </v-icon>
          <span>{{ tabItem.title }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <school-class :key="componentKey"></school-class>
        </v-tab-item>
        <v-tab-item>
          <school-class-move :key="componentKey1"></school-class-move>
        </v-tab-item>
        <v-tab-item>
          <school-picket-schedule :key="componentKey2"></school-picket-schedule>
        </v-tab-item>
        <v-tab-item>
          <school-setting :key="componentKey3"></school-setting>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </MainCard>
</template>

<script>
import MainCard from '@/views/components/MainCard.vue'
import SchoolClass from './SchoolClass.vue'
import SchoolClassMove from './SchoolClassMove.vue'
import SchoolPicketSchedule from './SchoolPicketSchedule.vue'
import SchoolSetting from './SchoolSetting.vue'

export default {
  name: 'SchoolAdmin',
  components: {
    MainCard,
    SchoolClass,
    SchoolPicketSchedule,
    SchoolClassMove,
    SchoolSetting,
  },
  data() {
    return {
      tab: '',
      tabs: [{ title: 'Kelas' }, { title: 'Pindah Kelas' }, { title: 'Jadwal Piket' }, { title: 'Pengaturan Sekolah' }],
      componentKey: 0,
      componentKey1: 0,
      componentKey2: 0,
      componentKey3: 0,
    }
  },
  methods: {
    reRenderComponent(index) {
      switch (index) {
        case 0:
          this.componentKey += 1
          break
        case 1:
          this.componentKey1 += 1
          break
        case 2:
          this.componentKey2 += 1
          break
        case 3:
          this.componentKey3 += 1
          break
        default:
          break
      }
    },
  },
}
</script>

<style>
</style>
