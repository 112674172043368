<template>
  <div>
    <v-container class="pa-6 mx-0">
      <v-row
        class="align-center"
      >
        <v-col
          cols="12"
          md="4"
          xl="4"
          sm="12"
        >
          <h2>Jadwal Piket</h2>
          <small style="white-space: normal;">
            Halaman untuk menambahkan jadwal piket absen harian
          </small>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-container class="my-4">
      <v-row v-if="!isLoadingCard">
        <v-col
          v-for="(pickets, day) in picketSchedules"
          :key="day"
          md="6"
        >
          <v-card>
            <v-card-title class="text-capitalize">
              {{ day }}
            </v-card-title>
            <v-simple-table>
              <template>
                <thead>
                  <tr>
                    <th class="text-uppercase">
                      Nama Pegawai
                    </th>
                    <th class="text-uppercase">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="picket in pickets"
                    :key="picket.uuid"
                  >
                    <td>
                      {{ picket.employee.name }}
                    </td>
                    <td>
                      <a @click="destroy(picket.uuid)">

                        <div>
                          <v-img
                            width="20"
                            height="20"
                            src="@/assets/icons/trash.svg"
                            class="p-0"
                          >
                          </v-img>
                        </div>
                      </a>
                    </td>
                  </tr>
                  <tr v-show="!pickets.length">
                    <td
                      colspan="2"
                      class="text-center"
                    >
                      Belum ada pegawai
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-card-actions class="pa-4 justify-center">
              <v-btn
                color="primary"
                text
                @click="showFormAdd(day)"
              >
                Tambah Pegawai
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        v-else
      >
        <v-col
          v-for="i,index in 6"
          :key="index"
          md="6"
        >
          <v-skeleton-loader
            type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
    <ModalDialog
      :visible="modalDialog"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!picketSchedule.employee_uuid"
      :dialog="dialog"
      header="Pilih Pegawai"
      text-button="Tambah"
      @handler-button="add"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <v-autocomplete
            v-model="picketSchedule.employee_uuid"
            label="Pilih Pegawai"
            outlined
            dense
            :items="employees"
            item-value="uuid"
            item-text="name"
            clearable
            @change="handleEmployee($event)"
          ></v-autocomplete>
        </div>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import ModalDialog from '@/views/components/ModalDialog.vue'

export default {
  name: 'SchoolPicketSchedule',
  components: {
    ModalDialog,
  },
  data() {
    return {
      modalDialog: false,
      isLoadingButton: false,
      isLoadingTable: false,
      isLoadingCard: true,
      dialog: '',
      employees: [],
      employee_uuid: '',
      school_uuid: '',
      service: 'picketschedule',
      picketSchedule: {
        employee_uuid: '',
        day: '',
      },
      picketSchedules: [],
    }
  },
  mounted() {
    this.getSchoolUuid()
    this.getEmployee()
    this.listPicketSchedule()
  },
  methods: {
    resetForm() {
      this.picketSchedule.employee_uuid = []
    },
    showFormAdd(day) {
      this.resetForm()
      this.picketSchedule.day = day
      this.modalDialog = true
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school forum',
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
      })

      return true
    },
    async getEmployee(params = {}) {
      this.$services.ApiServices.list('employee', { ...params, school_uuid: this.school_uuid, per_page: 'all' }).then(
        ({ data }) => {
          this.employees = data.data
        },
        err => console.error(err),
      )
    },
    async listPicketSchedule(params = {}) {
      this.isLoadingTable = true
      await this.$services.ApiServices.list(this.service, { ...params, school_uuid: this.school_uuid }).then(
        ({ data }) => {
          this.picketSchedules = data.data
        },
        err => console.error(err),
      )
      this.isLoadingTable = false
      this.isLoadingCard = false
    },
    async add() {
      this.isLoadingButton = true
      await this.$services.ApiServices.add(this.service, this.picketSchedule).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
          this.modalDialog = false
        },
      )
      this.isLoadingButton = false
      this.modalDialog = false
      await this.listPicketSchedule()
    },
    async destroy(uuid) {
      await this.$services.ApiServices.destroy(this.service, uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
          this.modalDialog = false
        },
      )
      await this.listPicketSchedule()
    },
    handleEmployee(uuid) {
      this.picketSchedule.employee_uuid = uuid
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
