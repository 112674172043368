<template>
  <div>
    <v-container class="pa-6 mx-0">
      <v-row>
        <v-col>
          <h2>Pindah Kelas</h2>
          <small
            style="white-space: normal;"
          >Halaman untuk memindahkan kelas siswa</small>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>

    <v-container class="pa-6 mx-0">
      <v-row>
        <v-col
          md="
        6"
        >
          <h4 class="mb-4">
            Pilih kelas yang akan dipindah
          </h4>
          <v-row>
            <v-col md="8">
              <v-autocomplete
                v-model="change.old_class_uuid"
                label="Pilih Kelas"
                :items="old_classes"
                outlined
                item-value="uuid"
                item-text="name"
                dense
                clearable
                @change="handleClassFrom($event)"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-card>
            <v-card-title>
              <h5>Data Siswa/i</h5>
              <v-spacer></v-spacer>
              <small>{{ total_student ? total_student : 0 }} Dipilih</small>
            </v-card-title>

            <v-simple-table
              min-height="400"
              class="overflow-y-auto"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      <v-checkbox
                        v-model="allSelected"
                        @change="selectAll"
                      ></v-checkbox>
                    </th>
                    <th class="text-left">
                      NIS
                    </th>
                    <th class="text-left">
                      NAMA SISWA/I
                    </th>
                  </tr>
                </thead>
                <v-progress-linear
                  v-if="isLoadingTable"
                  indeterminate
                  color="primary"
                  :absolute="true"
                  :rounded="true"
                ></v-progress-linear>
                <tbody v-else>
                  <tr
                    v-for="(student,index) in students"
                    :key="index"
                  >
                    <td>
                      <v-checkbox
                        v-model="selected"
                        :value="student.uuid"
                        @change="handleCheckbox(student.uuid, selected)"
                      >
                      </v-checkbox>
                    </td>
                    <td>{{ student.nis }}</td>
                    <td>{{ student.name }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col md="6">
          <h4 class="mb-4">
            Pilih kelas tujuan
          </h4>
          <v-row>
            <v-col md="8">
              <v-autocomplete
                v-model="change.class_uuid"
                label="Pilih Kelas"
                :items="classes"
                outlined
                item-value="uuid"
                item-text="name"
                :disabled="!old_class_uuid"
                dense
                clearable
                @change="handleClassTo($event)"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-card>
            <v-card-title>
              <h5>Data Siswa/i</h5>
            </v-card-title>

            <v-simple-table
              min-height="400"
              class="custom-table"
            >
              <template
                v-slot:default
              >
                <thead class="">
                  <tr class="">
                    <th class="text-left">
                      NIS
                    </th>
                    <th class="text-left">
                      NAMA SISWA/I
                    </th>
                  </tr>
                </thead>
                <v-progress-linear
                  v-if="isLoadingTable2"
                  indeterminate
                  color="primary"
                  :absolute="true"
                  :rounded="true"
                ></v-progress-linear>
                <tbody v-else>
                  <tr
                    v-for="(student,index) in studentMoves"
                    :key="index"
                  >
                    <td class="py-6">
                      {{ student.nis }}
                    </td>
                    <td>{{ student.name }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col
          md="12"
          class="d-flex justify-center mb-2"
        >
          <v-btn
            color="primary"
            x-large
            dense
            class="px-6"
            :disabled="!formValid || !selected.length"
            :loading="isLoadingButton"
            @click="changeClass()"
          >
            Pindah Kelas
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'SchoolClassMove',
  data() {
    return {
      old_classes: [],
      classes: [],
      school: [],
      students: [],
      studentMoves: [],
      school_uuid: '',
      class_uuid: '',
      class_uuid_from: '',
      class_uuid_to: '',
      old_class_uuid: '',
      student_uuid: '',
      change: {
        old_class_uuid: '',
        class_uuid: '',
      },
      total_student: 0,
      service: 'changeclass',
      selected: [],
      allSelected: false,
      isLoadingTable: false,
      isLoadingTable2: false,
      isLoadingButton: false,
      formValid: false,
    }
  },
  computed: {
    // selectAll: {
    //   get() {
    //     return this.total_student ? this.selected.length === this.filtered.length : false
    //   },
    //   set(value) {
    //     const selected = []
    //     this.total_student = 0
    //     if (value) {
    //       this.filtered.forEach(student => {
    //         selected.push(student.uuid)
    //         this.total_student += 1
    //       })
    //     } else {
    //       this.selected = []
    //       this.total_student = 0
    //     }
    //     this.selected = selected
    //   },
    // },
  },
  watch: {
    change: {
      handler() {
        const valid = []
        const requiredField = ['old_class_uuid', 'class_uuid']
        Object.entries(this.change).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
  },
  mounted() {
    this.getSchoolUuid()
    this.getClass()
  },
  methods: {
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school',
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
      })

      return true
    },
    async selectAll() {
      if (this.allSelected) {
        const selected = this.students.map(student => student.uuid)
        this.selected = selected
        this.total_student = selected.length
      } else {
        this.selected = []
        this.total_student = 0
      }
    },
    async getClass(params = { school_uuid: this.school_uuid }) {
      await this.$services.ApiServices.list('class', {
        ...params,
        school_uuid: this.school_uuid,
      }).then(
        async ({ data }) => {
          this.old_classes = data.data
          this.classes = data.data.filter(item => item.uuid !== this.old_class_uuid)
        },
        err => console.error(err),
      )
    },
    async listStudentFrom(params = {}) {
      this.isLoadingTable = true
      await this.$services.ApiServices.list('student', {
        ...params,
        school_uuid: this.school_uuid,
        class: this.class_uuid_from,
      }).then(
        ({ data }) => {
          this.students = data.data.map((student, index) => ({
            ...student,
            index: index + 1,
          }))
        },
        err => console.error(err),
      )
      this.isLoadingTable = false
    },
    async listStudentTo(params = {}) {
      this.isLoadingTable2 = true
      await this.$services.ApiServices.list('student', {
        ...params,
        school_uuid: this.school_uuid,
        class: this.class_uuid_to,
      }).then(
        ({ data }) => {
          this.studentMoves = data.data
        },
        err => console.error(err),
      )
      this.isLoadingTable2 = false
    },
    async handleClassFrom(uuid) {
      if (uuid == null) {
        this.change.class_uuid = ''
        this.studentMoves = []
      }
      this.class_uuid = uuid
      this.old_class_uuid = this.class_uuid
      this.class_uuid_from = this.old_class_uuid
      this.allSelected = false

      await this.listStudentFrom()

      this.selectAll()
      await this.getClass()
    },
    async handleClassTo(uuid) {
      this.class_uuid = uuid
      this.class_uuid_to = this.class_uuid
      this.allSelected = false

      await this.listStudentTo()
    },
    handleCheckbox(studentUuid, selected) {
      this.student_uuid = studentUuid
      this.total_student = selected.length
    },
    async changeClass() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.selected.forEach(el => {
        this.formData.append('student_uuid[]', el)
      })
      this.formData.append('class_uuid', this.class_uuid)
      this.formData.append('old_class_uuid', this.old_class_uuid)
      await this.$services.ApiServices.add(this.service, this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => {
          console.error(err)
        },
      )
      this.selected = []
      this.allSelected = false
      this.total_student = 0
      this.isLoadingButton = false
      await this.listStudentTo()
      await this.listStudentFrom()
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style scoped>
.custom-table table thead tr th {
  padding: 24px 16px;
}
.custom-table table tbody tr td {
  padding: 23px 16px;
}
</style>
